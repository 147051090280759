@tailwind components;
@tailwind utilities;

@layer panda_reset, panda_base, panda_tokens, panda_recipes, panda_utilities;

html {
	scroll-behavior: smooth;
}

html,
body {
	font-family: Roboto, sans-serif;
	height: 100% !important;
	line-height: 0px;
}

body {
	margin: 0;
	max-height: 100vh;
}

#root {
	height: 100%;
	width: 100vw;
	max-height: 100vh;
	display: flex;
	flex-direction: column;
}

:root {
	--light-50: #f3f5f8;

	--dark: #26262b;
	--dark-2: #7d85a7;

	--primary-color: #214ce2;
	--primary-color-darker: #1b3fbb;
	--background-color: #eaeffa;
	--dark-green: #07767d;
	--success: #00cc25;
	--warning: #f59e0b;
	--danger: #ff1500;
	--border-radius: 8px;
	--border-radius-lg: 10px;
	--text: #36416e;
	--text-light: #7d85a7;
	--secondary-20: #dbf5e5;
	--brand-strong-100: #27e59b;
	--brand-strong-40: #a9f5d7;
	font-size: 16px;

	/* Spacing */
	--spacing-4: 0.25rem;
	--spacing-8: 0.5rem;
	--spacing-12: 0.75rem;
	--spacing-16: 1rem;
	--spacing-24: 1.5rem;
	--spacing-32: 2rem;
	--spacing-40: 2.5rem;
	/* Spacing */
}

#hubspot-messages-iframe-container {
	z-index: 90 !important;
}

/* WORKAROUND primereact/dropdown */

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight:not(.p-disabled) {
	background: var(--brand-strong-100) !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
	background: var(--brand-strong-40) !important;
}
.p-dropdown-item {
	font-family: Roboto, sans-serif !important;
	font-weight: 400 !important;
	line-height: 1.375rem !important;
	font-size: 0.875rem !important;
}
/* WORKAROUND primereact/dropdown */

/* WORKAROUND primereact/image component */

.p-image-action[data-pc-section="zoomoutbutton"],
.p-image-action[data-pc-section="zoominbutton"],
.p-image-action[data-pc-section="rotateleftbutton"],
.p-image-action[data-pc-section="rotaterightbutton"] {
	display: none !important;
}
.p-component-overlay {
	background-color: rgba(16, 24, 32, 0.65) !important;
}
@keyframes p-component-overlay-enter-animation {
	from {
		background-color: transparent;
	}
	to {
		background-color: rgba(16, 24, 32, 0.65) !important;
	}
}
@keyframes p-component-overlay-leave-animation {
	from {
		background-color: rgba(16, 24, 32, 0.65) !important;
	}
	to {
		background-color: transparent;
	}
}

/* WORKAROUND primereact/image component */
