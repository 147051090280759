@layer panda_reset, panda_base, panda_tokens, panda_recipes, panda_utilities;

@layer panda_reset{
  html,:host {
    --font-fallback: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-tab-size: 4;
    tab-size: 4;
    -webkit-tap-highlight-color: transparent;
    line-height: 1.5;
    font-family: var(--global-font-body, var(--font-fallback));
}

  *,::before,::after,::backdrop,::file-selector-button {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    border-width: 0px;
    border-style: solid;
    border-color: var(--global-color-border, currentColor);
}

  hr {
    height: 0px;
    color: inherit;
    border-top-width: 1px;
}

  body {
    height: 100%;
    line-height: inherit;
}

  img {
    border-style: none;
}

  img,svg,video,canvas,audio,iframe,embed,object {
    display: block;
    vertical-align: middle;
}

  img,video {
    max-width: 100%;
    height: var(--sizes-auto);
}

  h1,h2,h3,h4,h5,h6 {
    text-wrap: balance;
    font-size: inherit;
    font-weight: inherit;
}

  p,h1,h2,h3,h4,h5,h6 {
    overflow-wrap: break-word;
}

  ol,ul,menu {
    list-style: none;
}

  button,input:where([type='button'], [type='reset'], [type='submit']),::file-selector-button {
    appearance: button;
    -webkit-appearance: button;
}

  button,input,optgroup,select,textarea,::file-selector-button {
    font: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    letter-spacing: inherit;
    color: inherit;
    background: transparent;
}

  ::placeholder {
    opacity: 1;
    --placeholder-fallback: color-mix(in srgb, currentColor 50%, transparent);
    color: var(--global-color-placeholder, var(--placeholder-fallback));
}

  textarea {
    resize: vertical;
}

  table {
    text-indent: 0px;
    border-collapse: collapse;
    border-color: inherit;
}

  summary {
    display: list-item;
}

  small {
    font-size: 80%;
}

  sub,sup {
    position: relative;
    vertical-align: baseline;
    font-size: 75%;
    line-height: 0;
}

  sub {
    bottom: -0.25em;
}

  sup {
    top: -0.5em;
}

  dialog {
    padding: 0px;
}

  a {
    color: inherit;
    text-decoration: inherit;
}

  abbr:where([title]) {
    text-decoration: underline dotted;
}

  b,strong {
    font-weight: bolder;
}

  code,kbd,samp,pre {
    --font-mono-fallback: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New';
    font-feature-settings: normal;
    font-variation-settings: normal;
    font-family: var(--global-font-mono, var(--font-mono-fallback));
    font-size: 1em;
}

  progress {
    vertical-align: baseline;
}

  ::-webkit-search-decoration,::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

  ::-webkit-inner-spin-button,::-webkit-outer-spin-button {
    height: var(--sizes-auto);
}

  :-moz-ui-invalid {
    box-shadow: none;
}

  :-moz-focusring {
    outline: auto;
}

  [hidden] {
    display: none !important;
}
}

@layer panda_base{
  :root {
    --made-with-panda: '🐼';
}

  *,::before,::after,::backdrop {
    --blur: /*-*/ /*-*/;
    --brightness: /*-*/ /*-*/;
    --contrast: /*-*/ /*-*/;
    --grayscale: /*-*/ /*-*/;
    --hue-rotate: /*-*/ /*-*/;
    --invert: /*-*/ /*-*/;
    --saturate: /*-*/ /*-*/;
    --sepia: /*-*/ /*-*/;
    --drop-shadow: /*-*/ /*-*/;
    --backdrop-blur: /*-*/ /*-*/;
    --backdrop-brightness: /*-*/ /*-*/;
    --backdrop-contrast: /*-*/ /*-*/;
    --backdrop-grayscale: /*-*/ /*-*/;
    --backdrop-hue-rotate: /*-*/ /*-*/;
    --backdrop-invert: /*-*/ /*-*/;
    --backdrop-opacity: /*-*/ /*-*/;
    --backdrop-saturate: /*-*/ /*-*/;
    --backdrop-sepia: /*-*/ /*-*/;
    --gradient-from-position: /*-*/ /*-*/;
    --gradient-to-position: /*-*/ /*-*/;
    --gradient-via-position: /*-*/ /*-*/;
    --scroll-snap-strictness: proximity;
    --border-spacing-x: 0;
    --border-spacing-y: 0;
    --translate-x: 0;
    --translate-y: 0;
    --rotate: 0;
    --rotate-x: 0;
    --rotate-y: 0;
    --skew-x: 0;
    --skew-y: 0;
    --scale-x: 1;
    --scale-y: 1;
}
}

@layer panda_tokens{
  :where(:root, :host) {
    --colors-primary-20: rgb(207, 209, 210);
    --colors-primary-40: rgb(159, 163, 166);
    --colors-primary-80: rgb(64, 70, 77);
    --colors-primary-100: rgb(16, 24, 32);
    --colors-secondary-20: rgb(212, 250, 235);
    --colors-secondary-40: rgb(169, 245, 215);
    --colors-secondary-80: rgb(82, 234, 175);
    --colors-secondary-100: rgb(39, 229, 155);
    --colors-secondary-120: rgb(7, 158, 131);
    --colors-on-primary: rgb(244, 248, 249);
    --colors-on-secondary: rgb(16, 24, 32);
    --colors-shade-0: rgb(255, 255, 255);
    --colors-shade-5: rgb(243, 243, 244);
    --colors-shade-10: rgb(231, 232, 233);
    --colors-shade-20: rgb(207, 209, 210);
    --colors-shade-40: rgb(159, 163, 166);
    --colors-shade-60: rgb(112, 116, 121);
    --colors-shade-80: rgb(64, 70, 77);
    --colors-shade-100: rgb(16, 24, 32);
    --colors-success-0: rgb(237, 249, 238);
    --colors-success-20: rgb(209, 234, 210);
    --colors-success-100: rgb(24, 152, 29);
    --colors-info-0: rgb(238, 246, 254);
    --colors-info-20: rgb(212, 234, 255);
    --colors-info-100: rgb(39, 149, 254);
    --colors-warning-0: rgb(255, 252, 239);
    --colors-warning-20: rgb(255, 246, 214);
    --colors-warning-100: rgb(224, 168, 39);
    --colors-error-0: rgb(255, 242, 237);
    --colors-error-20: rgb(251, 221, 210);
    --colors-error-100: rgb(234, 86, 28);
    --colors-transaprent: rgba(0, 0, 0, 0);
    --spacing-0: 0rem;
    --spacing-2: 0.125rem;
    --spacing-4: 0.25rem;
    --spacing-8: 0.5rem;
    --spacing-10: 0.625rem;
    --spacing-12: 0.75rem;
    --spacing-16: 1rem;
    --spacing-20: 1.25rem;
    --spacing-24: 1.5rem;
    --spacing-32: 2rem;
    --spacing-40: 2.5rem;
    --spacing-64: 4rem;
    --spacing-80: 5rem;
    --spacing-96: 6rem;
    --radii-0: 0rem;
    --radii-4: 0.25rem;
    --radii-6: 0.375rem;
    --radii-8: 0.5rem;
    --radii-10: 0.625rem;
    --radii-12: 0.75rem;
    --radii-16: 1rem;
    --radii-20: 1.25rem;
    --radii-24: 1.5rem;
    --radii-100: 6.25rem;
    --radii-full: 9999rem;
    --fonts-default: Roboto, sans-serif;
    --font-weights-normal: 400;
    --font-weights-bold: 700;
    --font-weights-extra-bold: 900;
    --font-sizes-10: 0.625rem;
    --font-sizes-12: 0.75rem;
    --font-sizes-14: 0.875rem;
    --font-sizes-16: 1rem;
    --font-sizes-20: 1.25rem;
    --font-sizes-22: 1.375rem;
    --line-heights-10: 0.625rem;
    --line-heights-16: 1rem;
    --line-heights-18: 1.125rem;
    --line-heights-20: 1.25rem;
    --line-heights-22: 1.375;
    --line-heights-26: 1.625rem;
    --line-heights-28: 1.75rem;
    --shadows-flyout: -1px 4px 4px rgba(0, 0, 0, 0.03), 10px 10px 36px rgba(16, 24, 32, 0.1);
    --sizes-0: 0rem;
    --sizes-2: 0.125rem;
    --sizes-4: 0.25rem;
    --sizes-8: 0.5rem;
    --sizes-10: 0.625rem;
    --sizes-12: 0.75rem;
    --sizes-16: 1rem;
    --sizes-20: 1.25rem;
    --sizes-24: 1.5rem;
    --sizes-32: 2rem;
    --sizes-40: 2.5rem;
    --sizes-64: 4rem;
    --sizes-80: 5rem;
    --sizes-96: 6rem;
    --sizes-auto: auto;
    --sizes-full: 100%;
    --sizes-breakpoint-md: 576px;
    --sizes-breakpoint-lg: 992px;
    --sizes-breakpoint-xl: 1200px;
    --sizes-breakpoint-2xl: 1400px;
    --sizes-breakpoint-3xl: 1920px;
    --breakpoints-md: 576px;
    --breakpoints-lg: 992px;
    --breakpoints-xl: 1200px;
    --breakpoints-2xl: 1400px;
    --breakpoints-3xl: 1920px;
}
}

@layer panda_utilities{
  @layer compositions{
    .textStyle_h1 {
      font-family: var(--fonts-default);
      font-size: var(--font-sizes-20);
      line-height: var(--line-heights-26);
      font-weight: var(--font-weights-bold);
}

    .textStyle_h2 {
      font-size: var(--font-sizes-16);
}

    .textStyle_h2,.textStyle_h3 {
      font-family: var(--fonts-default);
      line-height: var(--line-heights-20);
      font-weight: var(--font-weights-bold);
}

    .textStyle_h3 {
      font-size: var(--font-sizes-14);
}

    .textStyle_h4 {
      font-family: var(--fonts-default);
      line-height: var(--line-heights-16);
      font-size: var(--font-sizes-12);
      font-weight: var(--font-weights-bold);
}

    .textStyle_p1 {
      line-height: var(--line-heights-28);
      font-size: var(--font-sizes-16);
}

    .textStyle_p1,.textStyle_p2 {
      font-family: var(--fonts-default);
      font-weight: var(--font-weights-normal);
}

    .textStyle_p2,.textStyle_p2Anchor {
      line-height: var(--line-heights-22);
      font-size: var(--font-sizes-14);
}

    .textStyle_p2Anchor {
      font-family: var(--fonts-default);
      font-weight: var(--font-weights-normal);
      text-decoration-line: underline;
}

    .textStyle_p3,.textStyle_p3Anchor {
      font-family: var(--fonts-default);
      line-height: var(--line-heights-18);
      font-size: var(--font-sizes-12);
      font-weight: var(--font-weights-normal);
}

    .textStyle_p3Anchor {
      text-decoration-line: underline;
}

    .textStyle_l1 {
      line-height: var(--line-heights-10);
      font-size: var(--font-sizes-10);
}

    .textStyle_l1,.textStyle_b1 {
      font-family: var(--fonts-default);
      font-weight: var(--font-weights-normal);
}

    .textStyle_b1 {
      line-height: var(--line-heights-16);
      font-size: var(--font-sizes-14);
}

    @media screen and (min-width: 36rem) {
      .textStyle_h1 {
        line-height: var(--line-heights-28);
        font-size: var(--font-sizes-22);
}
}
}

  .d_flex {
    display: flex;
}

  .gap_8 {
    gap: var(--spacing-8);
}

  .\--thickness_1px {
    --thickness: 1px;
}

  .w_100\% {
    width: 100%;
}

  .text_primary\.20 {
    color: var(--colors-primary-20);
}

  .text_primary\.40 {
    color: var(--colors-primary-40);
}

  .text_primary\.80 {
    color: var(--colors-primary-80);
}

  .text_primary\.100 {
    color: var(--colors-primary-100);
}

  .text_secondary\.20 {
    color: var(--colors-secondary-20);
}

  .text_secondary\.40 {
    color: var(--colors-secondary-40);
}

  .text_secondary\.80 {
    color: var(--colors-secondary-80);
}

  .text_secondary\.100 {
    color: var(--colors-secondary-100);
}

  .text_secondary\.120 {
    color: var(--colors-secondary-120);
}

  .text_onPrimary {
    color: var(--colors-on-primary);
}

  .text_onSecondary {
    color: var(--colors-on-secondary);
}

  .text_shade\.0 {
    color: var(--colors-shade-0);
}

  .text_shade\.5 {
    color: var(--colors-shade-5);
}

  .text_shade\.10 {
    color: var(--colors-shade-10);
}

  .text_shade\.20 {
    color: var(--colors-shade-20);
}

  .text_shade\.40 {
    color: var(--colors-shade-40);
}

  .text_shade\.60 {
    color: var(--colors-shade-60);
}

  .text_shade\.80 {
    color: var(--colors-shade-80);
}

  .text_shade\.100 {
    color: var(--colors-shade-100);
}

  .text_success\.0 {
    color: var(--colors-success-0);
}

  .text_success\.20 {
    color: var(--colors-success-20);
}

  .text_success\.100 {
    color: var(--colors-success-100);
}

  .text_info\.0 {
    color: var(--colors-info-0);
}

  .text_info\.20 {
    color: var(--colors-info-20);
}

  .text_info\.100 {
    color: var(--colors-info-100);
}

  .text_warning\.0 {
    color: var(--colors-warning-0);
}

  .text_warning\.20 {
    color: var(--colors-warning-20);
}

  .text_warning\.100 {
    color: var(--colors-warning-100);
}

  .text_error\.0 {
    color: var(--colors-error-0);
}

  .text_error\.20 {
    color: var(--colors-error-20);
}

  .text_error\.100 {
    color: var(--colors-error-100);
}

  .text_transaprent {
    color: var(--colors-transaprent);
}

  .gap_4 {
    gap: var(--spacing-4);
}

  .w_full {
    width: var(--sizes-full);
}

  .p_4 {
    padding: var(--spacing-4);
}

  .scrollbar-width_thin {
    scrollbar-width: thin;
}

  .max-h_full {
    max-height: var(--sizes-full);
}

  .border_1px_solid {
    border: 1px solid;
}

  .rounded_12 {
    border-radius: var(--radii-12);
}

  .p_12 {
    padding: var(--spacing-12);
}

  .cursor_pointer {
    cursor: pointer;
}

  .gap_12 {
    gap: var(--spacing-12);
}

  .max-w_\[24rem\] {
    max-width: 24rem;
}

  .p_16 {
    padding: var(--spacing-16);
}

  .gap_16 {
    gap: var(--spacing-16);
}

  .bg_shade\.10 {
    background: var(--colors-shade-10);
}

  .w_\[fit-content\] {
    width: fit-content;
}

  .flex_1 {
    flex: 1 1 0%;
}

  .h_\[100\%\] {
    height: 100%;
}

  .pos_relative {
    position: relative;
}

  .w_\[100\%\] {
    width: 100%;
}

  .min-h_\[12\.5rem\] {
    min-height: 12.5rem;
}

  .h_\[7\.5rem\] {
    height: 7.5rem;
}

  .pos_absolute {
    position: absolute;
}

  .h_3\/4 {
    height: 75%;
}

  .text-align_right {
    text-align: right;
}

  .clamp_3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

  .max-w_8xl {
    max-width: 8xl;
}

  .mx_auto {
    margin-inline: auto;
}

  .px_4 {
    padding-inline: var(--spacing-4);
}

  .overflow_hidden {
    overflow: hidden;
}

  .max-h_\[40vh\] {
    max-height: 40vh;
}

  .max-w_full {
    max-width: var(--sizes-full);
}

  .aspect_16\/9 {
    aspect-ratio: 16/9;
}

  .pos_fixed {
    position: fixed;
}

  .inset_0 {
    inset: var(--spacing-0);
}

  .opacity_0 {
    opacity: 0;
}

  .opacity_0\.6 {
    opacity: 0.6;
}

  .transition_\[opacity_200ms_ease-out\] {
    transition: opacity 200ms ease-out;
}

  .transition_\[opacity_100ms_ease-out\] {
    transition: opacity 100ms ease-out;
}

  .d_none {
    display: none;
}

  .min-h_0 {
    min-height: var(--sizes-0);
}

  .gap_24 {
    gap: var(--spacing-24);
}

  .z_10 {
    z-index: 10;
}

  .w_\[3\.5rem\] {
    width: 3.5rem;
}

  .h_\[3\.5rem\] {
    height: 3.5rem;
}

  .rounded_\[50\%\] {
    border-radius: 50%;
}

  .rounded_\[1\.75rem\] {
    border-radius: 1.75rem;
}

  .w_\[2\.75rem\] {
    width: 2.75rem;
}

  .h_\[2\.75rem\] {
    height: 2.75rem;
}

  .rounded_\[1\.375rem\] {
    border-radius: 1.375rem;
}

  .max-w_\[calc\(100dvh_\*_16\/9\)\] {
    max-width: calc(100dvh * 16/9);
}

  .rounded_\[10px\] {
    border-radius: 10px;
}

  .rounded_\[none\] {
    border-radius: none;
}

  .h_auto\! {
    height: var(--sizes-auto) !important;
}

  .h_\[3rem\] {
    height: 3rem;
}

  .z_-1 {
    z-index: -1;
}

  .bg_\[linear-gradient\(180deg\,_rgba\(0\,_0\,_0\,_0\.70\)_0\%\,_rgba\(0\,_0\,_0\,_0\.00\)_98\.48\%\)\] {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 98.48%);
}

  .bg_\[linear-gradient\(0deg\,_\#000_0\%\,_rgba\(0\,_0\,_0\,_0\.00\)_98\.48\%\)\] {
    background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 98.48%);
}

  .rounded_10 {
    border-radius: var(--radii-10);
}

  .p_24 {
    padding: var(--spacing-24);
}

  .h_\[7rem\] {
    height: 7rem;
}

  .z_-10 {
    z-index: -10;
}

  .transform_translate\(-50\%\,_-50\%\) {
    transform: translate(-50%, -50%);
}

  .max-w_\[57rem\] {
    max-width: 57rem;
}

  .h_full {
    height: var(--sizes-full);
}

  .d_grid {
    display: grid;
}

  .bg_shade\.5 {
    background: var(--colors-shade-5);
}

  .gap_10px {
    gap: 10px;
}

  .w_screen {
    width: 100vw;
}

  .h_screen {
    height: 100vh;
}

  .bg_shade\.0 {
    background: var(--colors-shade-0);
}

  .shadow_flyout {
    box-shadow: var(--shadows-flyout);
}

  .my_24\! {
    margin-block: var(--spacing-24) !important;
}

  .w_3\/4 {
    width: 75%;
}

  .text-align_center {
    text-align: center;
}

  .pos_sticky {
    position: sticky;
}

  .z_1 {
    z-index: 1;
}

  .z_99999 {
    z-index: 99999;
}

  .flex_0_0_auto {
    flex: 0 0 auto;
}

  .rounded_9999px {
    border-radius: 9999px;
}

  .w_\[2\.5rem\] {
    width: 2.5rem;
}

  .h_\[2\.5rem\] {
    height: 2.5rem;
}

  .gap_32 {
    gap: var(--spacing-32);
}

  .white-space_nowrap {
    white-space: nowrap;
}

  .min-h_\[1px\] {
    min-height: 1px;
}

  .px_16 {
    padding-inline: var(--spacing-16);
}

  .py_20 {
    padding-block: var(--spacing-20);
}

  .bg_secondary\.40 {
    background: var(--colors-secondary-40);
}

  .rounded_6 {
    border-radius: var(--radii-6);
}

  .h_\[6\.5rem\] {
    height: 6.5rem;
}

  .transform_\[translateY\(1\.5rem\)\] {
    transform: translateY(1.5rem);
}

  .h_\[2\.25rem\] {
    height: 2.25rem;
}

  .transform_\[translateY\(8px\)\] {
    transform: translateY(8px);
}

  .shadow_\[0px_4px_8px_rgba\(0\,_0\,_0\,_0\.1\)\] {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

  .opacity_0\.5 {
    opacity: 0.5;
}

  .pointer-events_none {
    pointer-events: none;
}

  .py_8 {
    padding-block: var(--spacing-8);
}

  .min-h_\[3\.75rem\] {
    min-height: 3.75rem;
}

  .text-align_left {
    text-align: left;
}

  .max-w_\[25rem\] {
    max-width: 25rem;
}

  .d_inline-block {
    display: inline-block;
}

  .gap_\[1\.5rem\] {
    gap: 1.5rem;
}

  .gap_\[0\.5rem\] {
    gap: 0.5rem;
}

  .bg_onPrimary {
    background: var(--colors-on-primary);
}

  .px_32 {
    padding-inline: var(--spacing-32);
}

  .py_24 {
    padding-block: var(--spacing-24);
}

  .w_\[3rem\] {
    width: 3rem;
}

  .w_\[2\.375rem\] {
    width: 2.375rem;
}

  .h_\[2\.375rem\] {
    height: 2.375rem;
}

  .w_\[2rem\] {
    width: 2rem;
}

  .h_\[2rem\] {
    height: 2rem;
}

  .rounded_100 {
    border-radius: var(--radii-100);
}

  .bg_secondary\.120 {
    background: var(--colors-secondary-120);
}

  .justify_space-around {
    justify-content: space-around;
}

  .flex_row {
    flex-direction: row;
}

  .border-block-end-width_var\(--thickness\) {
    border-block-end-width: var(--thickness);
}

  .flex_column {
    flex-direction: column;
}

  .mt_16 {
    margin-top: var(--spacing-16);
}

  .overflow-y_auto {
    overflow-y: auto;
}

  .justify_space-between {
    justify-content: space-between;
}

  .border_shade\.20 {
    border-color: var(--colors-shade-20);
}

  .pr_32 {
    padding-right: var(--spacing-32);
}

  .items_center {
    align-items: center;
}

  .self_stretch {
    align-self: stretch;
}

  .justify-self_stretch {
    justify-self: stretch;
}

  .justify_center {
    justify-content: center;
}

  .grow_1 {
    flex-grow: 1;
}

  .top_0 {
    top: var(--spacing-0);
}

  .left_0 {
    left: var(--spacing-0);
}

  .right_0 {
    right: var(--spacing-0);
}

  .bg-img_linear-gradient\(180deg\,_rgba\(0\,_0\,_0\,_0\.80\)_3\.88\%\,_rgba\(0\,_0\,_0\,_0\.00\)_97\.64\%\) {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.80) 3.88%, rgba(0, 0, 0, 0.00) 97.64%);
}

  .items_start {
    align-items: start;
}

  .grow_0_1_auto {
    flex-grow: 0 1 auto;
}

  .justify-self_baseline {
    justify-self: baseline;
}

  .mt_auto {
    margin-top: auto;
}

  .ml_auto {
    margin-left: auto;
}

  .mr_auto {
    margin-right: auto;
}

  .fs_20 {
    font-size: var(--font-sizes-20);
}

  .fw_extraBold {
    font-weight: var(--font-weights-extra-bold);
}

  .ml_80 {
    margin-left: var(--spacing-80);
}

  .mt_-16 {
    margin-top: calc(var(--spacing-16) * -1);
}

  .pb_0 {
    padding-bottom: var(--spacing-0);
}

  .mt_32 {
    margin-top: var(--spacing-32);
}

  .pt_32 {
    padding-top: var(--spacing-32);
}

  .pb_24 {
    padding-bottom: var(--spacing-24);
}

  .bg_shade\.100 {
    background-color: var(--colors-shade-100);
}

  .bg_shade\.80 {
    background-color: var(--colors-shade-80);
}

  .pl_12 {
    padding-left: var(--spacing-12);
}

  .pr_12 {
    padding-right: var(--spacing-12);
}

  .pt_12 {
    padding-top: var(--spacing-12);
}

  .justify_flex-end {
    justify-content: flex-end;
}

  .bg_secondary\.100 {
    background-color: var(--colors-secondary-100);
}

  .pl_4 {
    padding-left: var(--spacing-4);
}

  .bg_shade\.100\/50 {
    --mix-backgroundColor: color-mix(in srgb, var(--colors-shade-100) 50%, transparent);
    background-color: var(--mix-backgroundColor, var(--colors-shade-100));
}

  .pb_12 {
    padding-bottom: var(--spacing-12);
}

  .bottom_0 {
    bottom: var(--spacing-0);
}

  .bg_\[transparent\] {
    background-color: transparent;
}

  .bottom_64 {
    bottom: var(--spacing-64);
}

  .left_\[-1\] {
    left: -1;
}

  .bg_primary\.100\/90 {
    --mix-backgroundColor: color-mix(in srgb, var(--colors-primary-100) 90%, transparent);
    background-color: var(--mix-backgroundColor, var(--colors-primary-100));
}

  .left_\[50\%\] {
    left: 50%;
}

  .top_\[50\%\] {
    top: 50%;
}

  .grid-rows_\[1fr_min-content\] {
    grid-template-rows: 1fr min-content;
}

  .grid-row-start_1 {
    grid-row-start: 1;
}

  .grid-row-end_1 {
    grid-row-end: 1;
}

  .mt_12 {
    margin-top: var(--spacing-12);
}

  .top_16 {
    top: var(--spacing-16);
}

  .left_16 {
    left: var(--spacing-16);
}

  .overflow-y_scroll {
    overflow-y: scroll;
}

  .items_stretch {
    align-items: stretch;
}

  .gap-y_16 {
    column-gap: var(--spacing-16);
}

  .pr_16 {
    padding-right: var(--spacing-16);
}

  .items_end {
    align-items: end;
}

  .self_end {
    align-self: end;
}

  .bg_shade\.10 {
    background-color: var(--colors-shade-10);
}

  .grid-cols_\[repeat\(1\,_1fr\)\] {
    grid-template-columns: repeat(1, 1fr);
}

  .grid-cols_\[20\%_1fr\] {
    grid-template-columns: 20% 1fr;
}

  .pt_4 {
    padding-top: var(--spacing-4);
}

  .bg_shade\.5 {
    background-color: var(--colors-shade-5);
}

  .mt_8 {
    margin-top: var(--spacing-8);
}

  .pt_64 {
    padding-top: var(--spacing-64);
}

  .pb_32 {
    padding-bottom: var(--spacing-32);
}

  .border_secondary\.80 {
    border-color: var(--colors-secondary-80);
}

  .border-style_solid {
    border-style: solid;
}

  .border-w_2px {
    border-width: 2px;
}

  .border-style_none {
    border-style: none;
}

  .\[\&_span\]\:clamp_6 span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}

  .\[\&\.tka-video-controls_\.tka-bottom-controls\]\:d_none.tka-video-controls .tka-bottom-controls {
    display: none;
}

  .hover\:bg_shade\.80:is(:hover, [data-hover]) {
    background: var(--colors-shade-80);
}

  .hover\:text_shade\.0:is(:hover, [data-hover]) {
    color: var(--colors-shade-0);
}

  .hover\:transition_\[all_0\.2s_ease\]:is(:hover, [data-hover]) {
    transition: all 0.2s ease;
}

  .hover\:fill_shade\.0:is(:hover, [data-hover]) {
    fill: var(--colors-shade-0);
}

  .hover\:cursor_default:is(:hover, [data-hover]) {
    cursor: default;
}

  .hover\:opacity_0\.6:is(:hover, [data-hover]) {
    opacity: 0.6;
}

  .hover\:cursor_pointer:is(:hover, [data-hover]) {
    cursor: pointer;
}

  .hover\:opacity_1:is(:hover, [data-hover]) {
    opacity: 1;
}

  .hover\:\[\&_svg\]\:fill_shade\.0:is(:hover, [data-hover]) svg {
    fill: var(--colors-shade-0);
}

  @media screen and (min-width: 36rem) {
    .md\:px_6 {
      padding-inline: 6px;
}
    .md\:d_none {
      display: none;
}
    .md\:p_20 {
      padding: var(--spacing-20);
}
    .md\:h_\[5\.5rem\] {
      height: 5.5rem;
}
    .md\:p_40 {
      padding: var(--spacing-40);
}
    .md\:w_\[8rem\] {
      width: 8rem;
}
    .md\:px_16 {
      padding-inline: var(--spacing-16);
}
    .md\:min-w_\[3rem\] {
      min-width: 3rem;
}
    .md\:w_\[7rem\] {
      width: 7rem;
}
    .md\:pos_static {
      position: static;
}
    .md\:min-w_\[22rem\] {
      min-width: 22rem;
}
    .md\:w_\[4rem\] {
      width: 4rem;
}
    .md\:min-w_\[4rem\] {
      min-width: 4rem;
}
    .md\:pb_40 {
      padding-bottom: var(--spacing-40);
}
    .md\:pl_8 {
      padding-left: var(--spacing-8);
}
    .md\:pl_20 {
      padding-left: var(--spacing-20);
}
    .md\:pr_20 {
      padding-right: var(--spacing-20);
}
    .md\:pb_20 {
      padding-bottom: var(--spacing-20);
}
    .md\:flex_row {
      flex-direction: row;
}
    .md\:items_center {
      align-items: center;
}
    .md\:justify_center {
      justify-content: center;
}
    .md\:justify_space-between {
      justify-content: space-between;
}
    .md\:flex_column {
      flex-direction: column;
}
    .md\:grid-cols_\[repeat\(2\,_1fr\)\] {
      grid-template-columns: repeat(2, 1fr);
}
}

  @media (min-width: 768px) {
    .\[\@media_\(min-width\:_768px\)\]\:d_flex {
      display: flex;
}
    .\[\@media_\(min-width\:_768px\)\]\:flex_row {
      flex-direction: row;
}
    .\[\@media_\(min-width\:_768px\)\]\:justify_space-between {
      justify-content: space-between;
}
    .\[\@media_\(min-width\:_768px\)\]\:pt_16 {
      padding-top: var(--spacing-16);
}
}

  @media screen and (min-width: 62rem) {
    .lg\:px_8 {
      padding-inline: var(--spacing-8);
}
    .lg\:w_\[6\.5rem\] {
      width: 6.5rem;
}
    .lg\:h_\[6\.5rem\] {
      height: 6.5rem;
}
    .lg\:w_\[57\.5rem\]\! {
      width: 57.5rem !important;
}
    .lg\:max-w_\[57\.5rem\]\! {
      max-width: 57.5rem !important;
}
    .lg\:grid-cols_\[repeat\(3\,_1fr\)\] {
      grid-template-columns: repeat(3, 1fr);
}
    .lg\:flex_row {
      flex-direction: row;
}
    .\[\&\.tka-video-controls_\.tka-bottom-controls\]\:lg\:d_none.tka-video-controls .tka-bottom-controls {
      display: none;
}
    .\[\&\.tka-video-controls\:hover_\.tka-bottom-controls\]\:lg\:d_block.tka-video-controls:hover .tka-bottom-controls {
      display: block;
}
}

  @media screen and (min-width: 75rem) {
    .xl\:h_full {
      height: var(--sizes-full);
}
    .xl\:max-w_\[75rem\] {
      max-width: 75rem;
}
    .xl\:row-span_\[auto\] {
      grid-row: auto;
}
    .xl\:col-span_2\/3 {
      grid-column: 2/3;
}
    .xl\:d_flex {
      display: flex;
}
    .xl\:grid-cols_\[min-content_1fr\] {
      grid-template-columns: min-content 1fr;
}
    .xl\:pl_24 {
      padding-left: var(--spacing-24);
}
    .xl\:pr_40 {
      padding-right: var(--spacing-40);
}
    .xl\:justify_center {
      justify-content: center;
}
    .xl\:items_center {
      align-items: center;
}
    .xl\:grid-cols_\[repeat\(2\,_1fr\)\] {
      grid-template-columns: repeat(2, 1fr);
}
}

  @media screen and (min-width: 87.5rem) {
    .\32xl\:grid-cols_\[repeat\(3\,_1fr\)\] {
      grid-template-columns: repeat(3, 1fr);
}
}

  @media screen and (min-width: 120rem) {
    .\33xl\:w_\[85\%\] {
      width: 85%;
}
}

  @media screen and (max-width: 35.9975rem) {
    .mdDown\:h_screen {
      height: 100vh;
}
    .mdDown\:w_screen {
      width: 100vw;
}
}

  @media (orientation: landscape) {
    .landscape\:max-h_\[55dvh\] {
      max-height: 55dvh;
}
    .landscape\:pl_20 {
      padding-left: var(--spacing-20);
}
    .landscape\:pr_20 {
      padding-right: var(--spacing-20);
}
    .landscape\:pt_16 {
      padding-top: var(--spacing-16);
}
}
}